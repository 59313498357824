import * as CryptoJS from "crypto-js";

export type Obj = Record<string, any>

// export const upColor = 'rgb(247,82,95)';
export const upColor = 'rgb(255,0,0)';
export const downColor = 'rgb(5,161,174)';
export const dateFormat = 'YYYY-MM-DD';

/**
 *加密处理
 */
export const encryption = (params: any) => {
  let { data, type, param, key } = params;
  const result = JSON.parse(JSON.stringify(data));
  if (type === "Base64") {
    param.forEach((ele: string | number) => {
      result[ele] = btoa(result[ele]);
    });
  } else {
    param.forEach((ele: string | number) => {
      var data = result[ele];
      key = CryptoJS.enc.Latin1.parse(key);
      var iv = key;
      // 加密
      var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.NoPadding
      });
      result[ele] = encrypted.toString();
    });
  }
  return result;
};

export const specialNumber = (val: number) => {
  const [num, decimalRep] = val.toString().split('.')
  return val &&
    num.replace(/\B(?=(\d{3})+(?!\d))/g, ',').toString() + (decimalRep ? `.${decimalRep}` : '')
}
