import { observer } from 'mobx-react';
import Password from './password';
import './index.less';
import { Image, Tabs } from 'antd';

const prefixCls = 'ja-login';
const { TabPane } = Tabs;

const Login = observer(() => {

	return (
		<div className={prefixCls}>
			<div className={`${prefixCls}_container`}>
				<div className={`${prefixCls}_title`}>
					<Image src={`${process.env.PUBLIC_URL}/logo.png`} width={140} preview={false} />
				</div>
				{/* <Tabs className="tab_login_wrapper"> */}
				{(window as any)._LOGIN_TYPE_.includes('user_name') && (
					// <TabPane key="password" tab="密码登录">
					<Password />
					// </TabPane>
				)}
				{/* </Tabs> */}
			</div>
			<div className={`${prefixCls}_footer`} onClick={() => window.open('https://beian.miit.gov.cn/')}>皖ICP备2021000119号-2</div>
		</div>
	);
});

export default Login;
