import axios from 'axios';
import tokenStore from '@/store/tokenStore';
import userStore from '@/store/userStore';
import { message, notification } from 'antd';

// eslint-disable-next-line no-undef
const errorShowing: Record<string, NodeJS.Timeout> = {};
const showError = (msg: string) => {
  console.log(errorShowing);
  if (!errorShowing[msg]) {
    // message.error(msg);
    notification.error({
      message: '出错了',
      description: msg,
      duration: 2,
      placement: 'bottomRight'
    })
  } else {
    clearTimeout(errorShowing[msg]);
    delete errorShowing[msg];
  }
  errorShowing[msg] = setTimeout(() => {
    clearTimeout(errorShowing[msg]);
    delete errorShowing[msg];
  }, 800);
};

const dealStatus = (resp: any) => {
  const status = resp.status;
  if (status === 504 || status === 404) {
    console.error('服务器被吃了');
  } else if (status === 403) {
    showError('权限不足,请联系管理员!');
  } else if (status === 424 || status === 401) {
    showError('未授权，请重新登陆！');
    tokenStore.token = '';
    userStore.accountInfo = {}
    userStore.loginUserInfo = {};
    localStorage.setItem('HKCAP_LOGIN', JSON.stringify({ hkcap_token: '', isLoggedIn: false }));
    localStorage.removeItem('HKCAP_ACCOUNT')
    window.location.reload()
  } else {
    showError(`状态码：${status}，操作失败`);
  }

  return resp;
};

let REAL_BASE_URL = (window as any)._BASE_URL_ || window.location.origin;

export { REAL_BASE_URL };

const instance = axios.create({
  baseURL: REAL_BASE_URL,
  timeout: 60000,
});

instance.interceptors.request.use(
  (config: any) => {
    config.headers.Authorization = config.headers.Authorization || `Bearer ${tokenStore.token}`;
    return config;
  },
  (error: any) => {
    console.error('请求超时');
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response: any) => {
    if (response.status && response.status === 200 && response.data.code && response.data.code !== 0) {
      showError(response.data.msg);
      return Promise.reject(response.data.msg);
    } else {
      return response;
    }
  },
  (error: any) => {
    const res = error.response;
    if (res) {
      if (res.config?.handleError !== false) {
        dealStatus(res);
      }
    } else {
      // showError('网络请求错误');
    }
    return Promise.reject(error.response);
  }
);

export default instance;
