import React from 'react';
import { Form, Button, Input, message } from 'antd';
import { useSetState } from 'react-use';
import { Obj } from '@/utils/common';
import VerifySlide from './components/verifySlide';
import SliderVertify from './components/sliderVertify';
import { useNavigate } from 'react-router-dom';
import { LoginByUsername } from './store';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import './index.less';

interface UserPassFormProps {
  buttonText?: string;
}
export default function UserPassForm(props: UserPassFormProps) {
  const { buttonText = '登录' } = props;

  const navigate = useNavigate()
  const [state, setState] = useSetState({
    verifyVis: false,
    loading: false,
  });

  const [form] = Form.useForm(undefined);

  const showVerify = () => {
    setState({
      verifyVis: !state.verifyVis
    })
  }

  const verifySuccess = (params: Obj) => {
    loginByUsername({
      ...form.getFieldsValue(),
      code: params.captchaVerification
    })
  }

  const loginByUsername = async (params: Obj) => {
    setState({
      loading: true,
    })
    try {
      await LoginByUsername(params)
    navigate('/', { state: {} })
    } catch (error) {
    } finally {
      setState({
        loading: false,
      })
    }
  }

  const inputStyle = {
    width: '100%',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    boxShadow: 'none',
  }

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        onFinish={showVerify}
        form={form}
      >
        <Form.Item
          label="用户名"
          name="username"
          rules={[
            {
              required: true,
              message: '请输入用户名',
            },
            () => ({
              validator(_, value) {
                // @ts-ignore
                if (!new RegExp(/^[a-zA-Z]([a-zA-Z0-9_-]|[/])*$/).test(value)) {
                  return Promise.reject(new Error('用户名必须以字母开头，可以包含大小写字母、数字、_、-、/'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input style={inputStyle} prefix={<UserOutlined style={{color: '#1890ff'}}/>} />
        </Form.Item>
        <Form.Item
          label="密码"
          name="password"
          rules={[
            { required: true, message: '请输入密码!' },
            // () => ({
            //   validator(_, value) {
            //     return password(value);
            //   },
            // }),
          ]}
        >
          <Input.Password style={inputStyle} prefix={<LockOutlined style={{color: '#1890ff'}}/>} />
        </Form.Item>
        <Form.Item hidden name="captcha_uuid" />
        <Form.Item>
          <Button htmlType="submit" block type="primary" disabled={state.loading} style={{ height: 48, marginTop: 10, fontSize: 17, fontWeight: 500 }}>
            {buttonText}
          </Button>
        </Form.Item>
      </Form>
      {
        state.verifyVis && <VerifySlide success={verifySuccess} closeBox={showVerify} />
      }
      {/* {
        state.verifyVis && <SliderVertify />
      } */}
    </>
  );
}
