import { Obj } from "@/utils/common"
import request from "@/utils/request"
import qs from "qs";
import website from "@/const/website.js";
import tokenStore from "@@/src/store/tokenStore";
import userStore from "@@/src/store/userStore";

//获取验证图片  以及token
export function reqGet(data: Obj) {
	return request.get('/code', {
		params: data,
	});
}

//滑动或者点选验证
export function reqCheck(params: Obj) {
	return request.post('/code/check', undefined, { params })
}

export const loginByUsername = (params: Obj) => {
	const { username, password, code, randomStr } = params
	let grant_type = 'password'
	let dataObj = qs.stringify({ 'username': username, 'password': password })
	let basicAuth = 'Basic ' + window.btoa(website.formLoginClient)

	// 保存当前选中的 basic 认证信息
	userStore.basicAuth = basicAuth

	return request.post('/auth/oauth/token', dataObj,
		{
			params: { randomStr, code, grant_type },
			headers: {
				isToken: false,
				'TENANT-ID': '1',
				'Authorization': basicAuth
			},
		}
	)
}

export const getAccountInfo = () => {
	return request.get('/admin/user/info', {
		headers: {
			Authorization: `Bearer ${tokenStore.token}`
		}
	});
}

export const checkoutToken = (params: Obj) => {
	return request.get('/auth/oauth/check_token', {
		params,
		headers: {
			Authorization: 'Basic ' + window.btoa(website.formLoginClient)
		}
	});
}