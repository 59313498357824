import { autorun, observable } from 'mobx';

export class TokenStore {
  @observable token: string = '';

  constructor() {
    //@ts-ignore
    let tmp = JSON.parse(localStorage.getItem('HKCAP_LOGIN') || '{}')?.hkcap_token;
    if (tmp) {
      this.token = tmp;
    } else {
      this.token = '';
    }
  }
}
const tokenStore = new TokenStore();
autorun(() => {
  if (tokenStore.token) 
    localStorage.setItem('HKCAP_LOGIN', JSON.stringify({ hkcap_token: tokenStore.token, isLoggedIn: true }));
  else localStorage.setItem('HKCAP_LOGIN', JSON.stringify({ hkcap_token: tokenStore.token, isLoggedIn: false }));
});

// @ts-ignore
export default tokenStore;
