export const getOption = (xList: any[], yList: any[], data: any[]) => {
  const percent = yList.length < 50 ? 100 : yList.length < 100 ? 50 : yList.length < 200 ? 25 : 10
  const xp = 1 / 7 * 100
  return {
    tooltip: {
      position: 'top',
      formatter: (params: any) => {
        return params.data[1] + ':' + params.data[0]
      }
    },
    grid: { left: 100, right: 70 },
    xAxis: {
      type: 'category',
      data: xList,
      splitArea: {
        show: true
      }
    },
    yAxis: {
      type: 'category',
      data: yList,
      splitArea: {
        show: true
      }
    },
    dataZoom: [
      {
        type: 'slider',
        show: true,
        xAxisIndex: [0],
        start: 0,
        end: xp
      },
      {
        type: 'slider',
        show: true,
        yAxisIndex: [0],
        left: '97%',
        start: 0,
        end: percent
      },
      {
        type: 'inside',
        xAxisIndex: [0],
        start: 0,
        end: xp
      },
      {
        type: 'inside',
        yAxisIndex: [0],
        start: 0,
        end: percent
      }
    ],
    visualMap: {
      min: 0,
      max: 1,
      orient: 'horizontal',
      left: 'center',
      top: -65
    },
    series: [
      {
        type: 'heatmap',
        data: data,
        // itemStyle: { borderRadius: [50, 50, 0, 0] },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
};
