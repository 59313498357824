import API from "@@/src/api"
import PageHeader from "@@/src/components/pageHeader"
import PageSearch from "@@/src/components/pageSearch"
import { useSetState, useWindowSize } from "react-use"
import './index.less';
import ReactEcharts from 'echarts-for-react'
import { getOption } from "./chartOption";

const Index = () => {

  const [state, setState] = useSetState<any>({
    codeLoading: false,
    data: [],
    stockList: [],
    timeList: []
  })

  const dateFormat = "YYYY-MM-DD"
  const { height } = useWindowSize()

  const fetchOps = async (value: string) => {
    setState({
      codeLoading: true,
    })
    try {
      const res = await API.searchPlate({ query: value });
      setState({
        searchOptions: res.data.data.map((i: any) => ({ label: `${i.name}(${i.tsCode})`, value: i.tsCode }))
      })
    } catch (error) {

    } finally {
      setState({
        codeLoading: false,
      })
    }
  }

  const handleSearch = (newValue: string) => {
    if (newValue) {
      fetchOps(newValue);
    } else {
      setState({
        searchOptions: []
      });
    }
  };

  const searchFields = [
    {
      label: "板块代码", name: "indexCode", type: "单文本", required: true, options: state.searchOptions,
      settings: {
        selectCom: true,
        handleSearch,
        loading: state.codeLoading
      }
    },
    { label: "日期", name: "date", type: "日期", showTime: false, dateFormat, required: true },
  ]

  const fetchRecordSummary = async (searchParams?: any) => {
    setState({
      loading: true,
    })
    let params: any = {
      ...searchParams,
      date: searchParams.date.format('YYYYMMDD')
    }
    if (searchParams?.backDate) {
      params.backDate = params.backDate.format(dateFormat)
    }
    let res = await API.getAbnormalMatrix(params)
    const { stockList, timeList, xyList } = res.data.data
    let list: any[] = []
    xyList.forEach((v: any) => {
      list.push([v.time, v.tsName, 1])
    })
    setState({
      data: list,
      stockList,
      timeList,
      loading: false,
    })
  }

  return <div>
    <PageHeader title="板块异动矩阵" />
    <PageSearch searchFields={searchFields} search={fetchRecordSummary} noReset />
    <div style={{ height: height - 190 }}>
      <ReactEcharts option={getOption(state.timeList, state.stockList, state.data)} style={{ height: '100%' }} showLoading={state.loading} />
    </div>
  </div>
}

export default Index
