import { CSSProperties } from 'react';
import './index.less';

interface PageHeaderProps {
	title: string;
	style?: CSSProperties
}
const Index = (props: PageHeaderProps) => {
	return <div>
		<div className="page_header_title" style={props.style || {}}>{props.title}</div>
	</div>
}

export default Index
