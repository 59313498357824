import React, { Suspense, useEffect } from 'react';
import { Menu, message, Spin } from 'antd';
import Layout from '@/components/layout';
import { Link, Outlet, useMatch, useNavigate } from 'react-router-dom';
import { BorderOutlined, DesktopOutlined, FileSearchOutlined } from '@ant-design/icons';
// import './index.less'
import { userMenu } from '@@/src/utils/constants';
import SubMenu from 'antd/lib/menu/SubMenu';
import { checkoutToken } from '../login/api';
import tokenStore from '@@/src/store/tokenStore';
import userStore from '@@/src/store/userStore';
import { useLocation, useSetState } from 'react-use';

const Index = () => {
  const match = useMatch('/:page');
  const page = match?.params.page || '';
  const location = useLocation()
  const navigate = useNavigate()
  const [state, setState] = useSetState({
    validTk: false
  })
  useEffect(() => {
    checkoutToken({ token: tokenStore.token }).then(res => {
      if (res.status === 200) {
        setState({
          validTk: true
        })
      }
    }).catch(() => {
      userStore.resetStore()
      navigate('/login')
    })
  }, [])

  return state.validTk && (
    <Layout
      siderTitle="HkCap"
      sider={
        <Menu
          theme="dark"
          mode="inline"
          // @ts-ignore
          selectedKeys={(location as any)?.hash.replace('#/', '').split('/')[0] || 'wel'}
          defaultOpenKeys={[]}
        >
          {userMenu().map((menu: any) => {
            if (menu.subMenu) {
              return (
                <SubMenu key={menu.key} icon={menu.icon || <DesktopOutlined />} title={menu.title}>
                  {menu.subMenu.map((c: any) => (
                    <Menu.Item key={c.key}>
                      <Link to={c.path} state={{ name: c.title }}>
                        {c.title}
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item key={menu.key} icon={menu.icon || <BorderOutlined />} onClick={() => {
                  if ('tradingView' === menu.key) {
                    window.open(`${window.origin}/#/tradingView`)
                  }
                }}>
                  {
                    'tradingView' !== menu.key ? (
                      <Link to={menu.path} state={{ name: menu.title }}>
                        {menu.title}
                      </Link>
                    ) : menu.title
                  }
                </Menu.Item>
              );
            }
          })}
        </Menu>
      }
      content={
        <Suspense
          fallback={
            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spin spinning></Spin>
            </div>
          }
        >
          <Outlet />
        </Suspense>
      }
    />
  );
};
export default Index