import { DashboardOutlined, DotChartOutlined, FundOutlined, GithubOutlined, HeatMapOutlined, HomeOutlined, LineChartOutlined, PieChartOutlined, RadarChartOutlined, RocketOutlined, SlidersOutlined } from "@ant-design/icons";

export const userMenu = (permissions?: []) => {
  return [
    {
      title: '首页',
      key: 'wel',
      path: '/',
      icon: <HomeOutlined />,
    },
    {
      title: 'HQ-订单流',
      key: 'hqchart_orderFlow',
      path: 'hqchart_orderFlow',
      icon: <SlidersOutlined />,
      access: true,
    },
    {
      title: '板块异动矩阵',
      key: 'plate_abnormal_matrix',
      path: 'plate_abnormal_matrix',
      icon: <RadarChartOutlined />,
      access: true,
    },
    {
      title: 'TradingView',
      key: 'tradingView',
      path: 'tradingView',
      icon: <SlidersOutlined />,
      access: true,
    },
    {
      title: '历史回测',
      key: 'historyTradeBack',
      path: 'historyTradeBack',
      icon: <PieChartOutlined />,
      access: true,
    },
    {
      title: 'L2数据分析',
      key: 'level2DataAnalysis',
      path: 'level2DataAnalysis',
      icon: <RocketOutlined />,
      access: true,
    },
    {
      title: '分时图',
      key: 'timeDiagram',
      path: 'timeDiagram',
      icon: <LineChartOutlined />,
      access: true,
    },
    {
      title: '实时分时图',
      key: 'timeDiagramOnLine',
      path: 'timeDiagramOnLine',
      icon: <LineChartOutlined />,
      access: true,
    },
    {
      title: 'L2成交明细',
      key: 'level2TransactionDetail',
      path: 'level2TransactionDetail',
      icon: <FundOutlined />,
      access: true,
    },
    // {
    //   title: '板块异动',
    //   key: 'plateAnomal',
    //   path: 'plateAnomal',
    //   icon: <HeatMapOutlined />,
    //   access: true,
    // },
    {
      title: '热点题材',
      key: 'hotTopics',
      path: 'hotTopics',
      icon: <DotChartOutlined />,
      access: true,
    },
    {
      title: '监控预警',
      key: 'monitorWarning',
      path: 'monitorWarning',
      icon: <DashboardOutlined />,
      access: true,
    },
    {
      title: 'Test',
      key: 'test',
      path: 'test',
      icon: <GithubOutlined />,
      access: true,
    },
  ].filter(m => m.access === undefined || m.access);
};
