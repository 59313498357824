import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, Menu, Avatar, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import tokenStore from '@/store/tokenStore';
import userStore from '@/store/userStore';
import API from '@@/src/api';

import './index.less';
import { observer } from 'mobx-react';

const Index = () => {
  const navigate = useNavigate()

  const onLogout = () => {
    Modal.confirm({
      title: '提示',
      content: '是否退出系统, 是否继续?',
      cancelText: '取消',
      okText: '确认',
      onOk: async () => {
        await API.logout()
        userStore.resetStore()
        navigate('/login')
      }
    })
  };

  const menu = (
    <Menu>
      <Menu.Item key={'account'}>
        <Link to='/account' state={{ name: '个人中心' }} >个人中心</Link>
      </Menu.Item>
      <>
        <Menu.Divider />
        <Menu.Item key={'logout'}>
          <span onClick={onLogout}>退出登录</span>
        </Menu.Item>
      </>
    </Menu>
  );

  const user = userStore.loginUserInfo;
  const imageUrl = '';

  return (
    <div className="app_header_avatar">
      <span className={`app_header-nick`}>{user.用户姓名}</span>
      <Dropdown overlay={menu} placement="bottomRight">
        {imageUrl ? (
          <Avatar size="small" src={imageUrl} style={{ cursor: 'pointer' }} />
        ) : (
          <Avatar size="small" icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
        )}
      </Dropdown>
    </div>
  );
};

export default observer(Index);
