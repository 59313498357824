import { action, observable, reaction } from 'mobx';
import { getAccountInfo } from '../pages/login/api';
import tokenStore from './tokenStore';

class UserStore {
  @observable loginUserInfo = {} as any;
  @observable accountInfo = {} as any;
  @observable basicAuth = '';

  @action.bound
  async fetchUserInfo() {
    if (this.accountInfo?.user_id) {
      let useRes = await getAccountInfo();
      if(!useRes.data.code) {
        this.loginUserInfo = useRes.data.data
      }
    }
  }

  constructor() {
    reaction(
      () => this.loginUserInfo,
      (userInfo:any) => {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
      }
    );
    reaction(
      () => this.accountInfo,
      async () => {
        this.fetchUserInfo();
      }
    );
  }

  @action
  resetStore() {
    localStorage.removeItem('HKCAP_LOGIN')
    localStorage.removeItem('HKCAP_ACCOUNT')
    tokenStore.token = ''
    this.loginUserInfo = {}
    this.accountInfo = {};
  }
}

export default new UserStore();
