import API from "@@/src/api"
import PageHeader from "@@/src/components/pageHeader"
import PageSearch from "@@/src/components/pageSearch"
import { useSetState, useWindowSize } from "react-use"
import $ from 'jquery';
import KLineChart from './KLineChart';
import './index.less'
import { useEffect } from "react";
import { isEmpty } from "lodash";
import WebFont from './jscommon/umychart.resource/js/webfont.js';

const Index = () => {

  const [state, setState] = useSetState<any>({
    values: {},
    codeLoading: false,
  })

  const dateFormat = "YYYY-MM-DD"
  const { height } = useWindowSize()
  const dom = document.getElementById('kline')

  const fetchOps = async (value: string) => {
    setState({
      codeLoading: true,
    })
    try {
      const res = await API.searchSymbol({ query: value });
      setState({
        searchOptions: res.data.data.map((i: any) => ({ label: `${i.name}(${i.tsCode})`, value: i.tsCode }))
      })
    } catch (error) {

    } finally {
      setState({
        codeLoading: false,
      })
    }
  }

  const handleSearch = (newValue: string) => {
    if (newValue) {
      fetchOps(newValue);
    } else {
      setState({
        searchOptions: []
      });
    }
  };

  const searchFields = [
    {
      label: "股票代码", name: "tsCode", type: "单文本", required: true, options: state.searchOptions,
      settings: {
        selectCom: true,
        handleSearch,
        loading: state.codeLoading
      }
    },
    { label: "日期", name: "date", type: "日期", showTime: false, dateFormat, required: true },
  ]

  useEffect(() => {
    if (dom && !isEmpty(state.values)) {
      WebFont.load({ custom: { families: ['iconfont'] } });   //预加载下iconfont资源
      $('#kline').empty()
      var klineControl = new KLineChart(dom, {
        ...state.values,
        date: state.values.date.format('YYYYMMDD')
      });
      klineControl.Create();
      $("#button_1").click(function () { klineControl.ChangeKLineDrawType(0); });
      $("#button_2").click(function () { klineControl.ChangeKLineDrawType(5); });
    }
  }, [dom, state.values])

  const fetchRecordSummary = async (searchParams?: any) => {
    setState({
      loading: true,
    })
    let params: any = {
      descs: 'back_date',
      ...searchParams,
    }
    if (searchParams?.backDate) {
      params.backDate = params.backDate.format(dateFormat)
    }
    let res = await API.getHistoryTbList(params)
    const { records: data } = res.data.data
    setState({
      data,
      loading: false,
    })
  }

  return <div>
    <PageHeader title="HQ-订单流" />
    <PageSearch searchFields={searchFields} search={(values) => setState({ values })} noReset />
    <div style={{ height: height - 190 }}>
      <div id="kline"></div>
      <div>
        <span id='button_1' className="btn-style">K线图</span>
        <span id='button_2' className="btn-style">订单流</span>
      </div>
    </div>
  </div>
}

export default Index
