import tokenStore from "../store/tokenStore";
import request from "../utils/request";

const API = {
	logout() {
		return request.delete('/auth/token/logout');
	},
	getHistoryTbList(data: any) {
		return request.get('/stock/stockback/page', {
			params: data,
			headers: {
				Authorization: `Bearer ${tokenStore.token}`
			}
		});
	},
	getHistoryTbDetailList(data: any) {
		return request.get('/stock/stockbackdetail/page', {
			params: data,
		});
	},
	getL2AnalysisData(data: any) {
		return request.get('/stock/stocklevel2/analysis', {
			params: data,
		});
	},
	searchSymbol(data: any, path?: string) {
		return request.get(path || '/stock/stockinfo/search', {
			params: data,
		});
	},

	searchPlate(data: any) {
		return request.get('/stock/stockindex/search', {
			params: data,
		});
	},

	getTimeDiaData(data: any) {
		return request.get('/stock/stocklevel2/time-sharing-line', {
			params: data,
		});
	},

	downLoadSymbolFile(data: any) {
		return request.get('/stock/stocklevel2/dowFile', {
			params: data,
		});
	},

	getL2TacData(data: any) {
		return request.get('/stock/stocklevel2/level2-detail', {
			params: data,
		});
	},

	getHotTopicsData(data: any) {
		return request.get('/stock/stocksubject/list', {
			params: data,
		});
	},

	getHotTopicsById(id: string) {
		return request.get(`/stock/stocksubject/${id}`);
	},

	getHotTopicsNG(id: string) {
		return request.get(`/stock/stocksubject/cows/${id}`);
	},

	getCaldById(id: string) {
		return request.get(`/stock/stocksubject/limit/getCal/${id}`);
	},

	getHTDateList(data: any) {
		return request.get('/stock/stocksubject/getSubLimitByDate', {
			params: data,
		});
	},
	getSubWeak(data: any) {
		return request.get('/stock/stocksubject/getSubWeak', {
			params: data,
		});
	},
	getMonitor(data?: any) {
		return request.get('/stock/stocksubjectmonitor/getMonitor', {
			params: data,
		});
	},
	getLevel2joinorder(data?: any) {
		return request.get('/stock/level2joinorder/ma', {
			params: data,
		});
	},
	getLevel2RealTime(params?: any) {
		return request.get(`stock/level2order/real-time`, { params });
	},

	getPressurePop(params?: any) {
		return request.get('stock/level2order/pressure-and-prop', { params })
	},
	getAbnormalMatrix(params?: any) {
		return request.get('stock/flinkeffectbasicstock/abnormalMatrix', { params })
	},
}

export default API
