import React, { memo, useCallback } from 'react';
import UserPassForm from './form';

const prefixCls = 'hkcap-ui-login';

const Phone = memo(() => {

  return (
    <div className={`${prefixCls}-form`}>
      <UserPassForm />
    </div>
  );
});

export default Phone;
