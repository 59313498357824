import React from 'react';
import { useSetState } from 'react-use';
import { Layout as ALayout, Image } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import HeaderAvatar from '@/components/headerAvatar';
import './index.less';
import { Link, useLocation } from 'react-router-dom';

const { Header, Sider, Content } = ALayout;

interface LayoutProps {
  sider: React.ReactElement | ((collapsed: boolean) => React.ReactElement);
  /**可传[长文本，短文本], collapse时取短文本 */
  siderTitle: string | string[];
  content: React.ReactElement;
}
export default function Layout(props: LayoutProps) {
  const { sider, siderTitle, content } = props;
  const [state, setState] = useSetState({
    collapsed: false,
  });
  const location = useLocation();
  const siderNode = typeof sider === 'function' ? sider(state.collapsed) : sider;
  const toggle = () => {
    setState({
      collapsed: !state.collapsed,
    });
  };
  return (
    <div className="home_layout">
      <ALayout>
        <Sider trigger={null} collapsible collapsed={state.collapsed} breakpoint="lg" collapsedWidth="50">
          <div className="slider_logo">
            <Link to={'/'} state={{ name: '用户前台' }}>
              <div className="logo">
                <Image src={`${process.env.PUBLIC_URL}/logo.png`} width={40} preview={false} style={{ display: 'flex' }}/>
                {
                  !state.collapsed && <span style={{ marginLeft: 10 }}>Hk Cap</span>
                }
                {/* {!state.collapsed && (
                <Image src={`${process.env.PUBLIC_URL}/appname.svg`} preview={false} className="app_text" width={90} />
              )} */}
              </div>
            </Link>
          </div>
          <div className="sider_scoll_wrap">
            {siderNode}
          </div>
        </Sider>
        <ALayout className="site-layout">
          <Header className="site-layout-header-background" style={{ padding: 0 }}>
            {React.createElement(state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: toggle,
            })}
            <HeaderAvatar />
          </Header>
          <Content className="site-layout-content">{content}</Content>
        </ALayout>
      </ALayout>
    </div>
  );
}
