import tokenStore from "@@/src/store/tokenStore"
import userStore from "@@/src/store/userStore"
import { encryption } from "@@/src/utils/common"
import { message } from "antd"
import { loginByUsername } from "../api"

// 根据用户名登录
export function LoginByUsername(userInfo: any) {
	let user: any = {}
	if ((window as any)._PASSWORD_ENC_) {
		user = encryption({
			data: userInfo,
			key: 'pigxpigxpigxpigx',
			param: ['password']
		})
	} else {
		user = userInfo
	}
	return loginByUsername({ ...user, randomStr: 'blockPuzzle' }).then((response: any) => {
		tokenStore.token = response.data.access_token;
		userStore.accountInfo = response.data
		userStore.fetchUserInfo()
		localStorage.setItem('HKCAP_ACCOUNT', JSON.stringify(response.data));
		localStorage.setItem('HKCAP_LOGIN', JSON.stringify({ hkcap_token: tokenStore.token, isLoggedIn: true }));
		// commit('SET_ACCESS_TOKEN', data.access_token)
		// commit('SET_REFRESH_TOKEN', data.refresh_token)
		// commit('CLEAR_LOCK')
		return Promise.resolve(response.data)
	}).catch(error => {
		message.error(error.data.msg)
	})
}