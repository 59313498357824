import React, { lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";

import Login from "@/pages/login";
import Home from "@/pages/home";
import Wel from "@/pages/wel";
import OrderFlow from "@/pages/orderFlow";
import PlateAbnormalMatrix from "@/pages/plateAbnormalMatrix";

export const paths = {
  root: "/",
  login: "/login",
  tradingView: "/tradingView",
};

const NewTvChart = lazy(() => import("@/pages/newTvChart"));
const TradingView = lazy(() => import("@/pages/tradingView"));
const Test = lazy(() => import("@/pages/test"));
const HistoryTradeBack = lazy(() => import("@/pages/historyTradeBack"));
const HistoryTradeBackList = lazy(() => import("@/pages/historyTradeBack/list"));
const HistoryTradeBackDetail = lazy(() => import("@/pages/historyTradeBack/detail"));
const TsDetail = lazy(() => import("@/pages/historyTradeBack/tsDetail"));
const Level2DataAnalysis = lazy(() => import("@/pages/level2DataAnalysis"));
const TimeDiagram = lazy(() => import("@/pages/timeDiagram"));
const TimeDiagramOnLine = lazy(() => import("@/pages/timeDiagramOnLine"));
const Level2TransactionDetail = lazy(() => import("@/pages/l2TransactionDetail"));

const PlateAnomal = lazy(() => import("@/pages/plateAnomal"));
const PlateAnomalList = lazy(() => import("@/pages/plateAnomal/list"));
const HotTopics = lazy(() => import("@/pages/hotTopics"));
const HotTopicsList = lazy(() => import("@/pages/hotTopics/list"));
const HotTopicsDetail = lazy(() => import("@/pages/hotTopics/detail"));
const MonitorWarning = lazy(() => import("@/pages/monitorWarning"));
const MonitorWarningList = lazy(() => import("@/pages/monitorWarning/list"));

const Index = authed => {
  const routes = [
    {
      path: paths.root,
      element: authed ? <Home /> : <Navigate to={paths.login} />,
      children: [
        {
          index: true,
          element: <Wel />,
        },
        {
          path: "tradingView",
          element: authed ? <TradingView /> : <Navigate to={paths.login} />,
        },
        {
          path: "test",
          element: authed ? <Test /> : <Navigate to={paths.login} />,
        },
        {
          path: "historyTradeBack",
          element: authed ? <HistoryTradeBack /> : <Navigate to={paths.login} />,
          children: [
            {
              index: true,
              element: authed ? <HistoryTradeBackList /> : <Navigate to={paths.login} />,
            },
            {
              path: "detail/:batchId",
              element: authed ? <HistoryTradeBackDetail /> : <Navigate to={paths.login} />,
            },
            // {
            //   path: 'detail/:batchId/:tsCode',
            //   element: authed ? <TsDetail /> : <Navigate to={paths.login} />,
            // },
          ],
        },
        {
          path: "historyTradeBack/detail/:batchId/:tsCode",
          element: authed ? <TsDetail /> : <Navigate to={paths.login} />,
        },
        {
          path: "newTvChart",
          element: authed ? <NewTvChart /> : <Navigate to={paths.login} />,
        },
        {
          path: "level2DataAnalysis",
          element: authed ? <Level2DataAnalysis /> : <Navigate to={paths.login} />,
        },
        {
          path: "timeDiagram",
          element: authed ? <TimeDiagram /> : <Navigate to={paths.login} />,
        },
        {
          path: "timeDiagramOnLine",
          element: authed ? <TimeDiagramOnLine /> : <Navigate to={paths.login} />,
        },
        {
          path: "level2TransactionDetail",
          element: authed ? <Level2TransactionDetail /> : <Navigate to={paths.login} />,
        },
        {
          path: "plateAnomal",
          element: authed ? <PlateAnomal /> : <Navigate to={paths.login} />,
          children: [
            {
              index: true,
              element: authed ? <PlateAnomalList /> : <Navigate to={paths.login} />,
            },
          ],
        },
        {
          path: "plateAnomal/detail/:batchId/:tsCode",
          element: authed ? <TsDetail /> : <Navigate to={paths.login} />,
        },
        {
          path: "hotTopics",
          element: authed ? <HotTopics /> : <Navigate to={paths.login} />,
          children: [
            {
              index: true,
              element: authed ? <HotTopicsList /> : <Navigate to={paths.login} />,
            },
            {
              path: "detail/:id",
              element: authed ? <HotTopicsDetail /> : <Navigate to={paths.login} />,
            },
          ],
        },
        {
          path: '/monitorWarning',
          element: authed ? <MonitorWarning /> : <Navigate to={paths.login} />,
          children: [
            {
              index: true,
              element: authed ? <MonitorWarningList /> : <Navigate to={paths.login} />,
            },
          ],
        },
        {
          path: "/hqchart_orderFlow",
          element: authed ? <OrderFlow /> : <Navigate to={paths.login} />,
        },
        {
          path: "/plate_abnormal_matrix",
          element: authed ? <PlateAbnormalMatrix /> : <Navigate to={paths.login} />,
        },
      ],
    },
    {
      path: paths.login,
      element: authed ? <Navigate to={paths.root} /> : <Login />,
    },
  ];

  return routes;
};
export default Index;
