import React from 'react';
import './App.css';
import ConfigProvider from 'antd/es/config-provider';
import { useRoutes } from 'react-router-dom';
import routes from '@/routes/routes';
import { observer } from 'mobx-react';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
function App() {
  moment.locale('zh-cn');
  return (
    <ConfigProvider locale={zhCN}>
        <div className="App" id="app">{useRoutes(routes(JSON.parse(localStorage.getItem('HKCAP_LOGIN') || '{}').isLoggedIn))}</div>
    </ConfigProvider>
  );
}

export default observer(App);
