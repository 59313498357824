import { aesEncrypt } from '@/utils/ase';
import { Obj } from '@/utils/common';
import { CloseOutlined, ReloadOutlined, RightOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useEffect } from 'react';
import { useMount, useSetState } from 'react-use';
import { reqCheck, reqGet } from '../api';
import './index.less'

const Index = (props: Obj) => {
	const leftBarBorderColor = "#ddd"
	const vSpace = 5
	const explain = '向右滑动完成验证'
	const barSize = {
		width: "310px",
		height: "40px",
	}
	const imgSize = {
		width: "310px",
		height: "155px",
	}
	const [state, setState] = useSetState<any>({
		captchaType: "blockPuzzle",
		secretKey: "", //后端返回的加密秘钥 字段
		passFlag: "", //是否通过的标识
		backImgBase: '',//验证码背景图片
		blockBackImgBase: "",//验证滑块的背景图片
		backToken: "", //后端返回的唯一token值
		startMoveTime: "", //移动开始的时间
		endMovetime: "", //移动结束的时间
		tipsBackColor: "", //提示词的背景颜色
		showRefresh: true,
		leftBarWidth: undefined,
		moveBlockBackgroundColor: undefined,
		moveBlockLeft: 0,
		setSize: {
			imgHeight: 155,
			imgWidth: 330,
			barHeight: 0,
			barWidth: 0,
		},
		blockSize: {
			width: 50,
			height: 50,
		},
		status: false, //鼠标状态
		isEnd: false, //是够验证完成
		transitionLeft: "",
		transitionWidth: "",
		tipWords: "",
		startLeft: 0,
		iconColor: "",
	})

	const start = (e: any, barArea: any) => {
		e = e || window.event;
		if (!e.touches) {
			//兼容PC端
			var x = e.clientX;
		}
		setState({
			startLeft: Math.floor(
				x - barArea.getBoundingClientRect().left
			),
			startMoveTime: new Date(), //开始滑动的时间
		})
		if (!state.isEnd) {
			setState({
				text: "",
				moveBlockBackgroundColor: "#337ab7",
				leftBarBorderColor: "#337AB7",
				iconColor: "#fff",
				status: true
			})

			e.stopPropagation();
		}
	}

	const move = (e: any, barArea: any) => {
		if (state.status && state.isEnd === false) {
			if (!e.touches) {
				//兼容PC端
				var x = e.clientX;
			} else {
				//兼容移动端
				var x = e.touches[0].pageX;
			}
			let bar_area_left = barArea.getBoundingClientRect().left;
			let move_block_left: any = x - bar_area_left; //小方块相对于父元素的left值
			const bsize = parseInt(state.blockSize.width)
			if (move_block_left >= barArea.offsetWidth - parseInt((bsize / 2).toString()) - 2) {
				move_block_left = barArea.offsetWidth - parseInt((bsize / 2).toString()) - 2;
			}
			if (move_block_left <= 0) {
				move_block_left = parseInt((bsize / 2).toString());
			}
			//拖动后小方块的left值
			setState({
				moveBlockLeft: move_block_left - state.startLeft,
				leftBarWidth: move_block_left - state.startLeft
			})
		}
	}

	const end = () => {
		setState({
			endMovetime: new Date()
		})
		//判断是否重合
		if (state.status && state.isEnd == false) {
			var moveLeftDistance = state.moveBlockLeft;
			moveLeftDistance =
				(moveLeftDistance * 310) / parseInt(state.setSize.imgWidth);
			let data = {
				captchaType: state.captchaType,
				pointJson: state.secretKey
					? aesEncrypt(
						JSON.stringify({ x: moveLeftDistance - 0.0000001, y: 5.0 }),
						state.secretKey
					)
					: JSON.stringify({ x: moveLeftDistance, y: 5.0 }),
				token: state.backToken,
			};
			reqCheck(data).then((response: Obj) => {
				let res = response.data.data;
				if (res.repCode == "0000") {
					setState({
						moveBlockBackgroundColor: "#5cb85c",
						leftBarBorderColor: "#5cb85c",
						iconColor: "#fff",
						iconClass: "icon-check",
						showRefresh: false,
						isEnd: true,
						passFlag: true,
						tipWords: '验证成功',
						// tipWords: `${-(
						// 	(state.endMovetime - state.startMoveTime) /
						// 	1000
						// ).toFixed(2)}s验证成功`,
					})

					var captchaVerification = state.secretKey
						? aesEncrypt(
							state.backToken +
							"---" +
							JSON.stringify({ x: moveLeftDistance, y: 5.0 }),
							state.secretKey
						)
						: state.backToken +
						"---" +
						JSON.stringify({ x: moveLeftDistance, y: 5.0 });
					setTimeout(() => {
						setState({
							tipWords: "",
						})
						props.closeBox?.();
						props.success?.({ captchaVerification })
					}, 1000);
				} else {
					setState({
						moveBlockBackgroundColor: "#d9534f",
						leftBarBorderColor: "#d9534f",
						iconColor: "#fff",
						iconClass: "icon-close",
						passFlag: false,
						tipWords: '验证失败'
					})
					refresh();
					props.error?.()
					setTimeout(() => {
						setState({
							tipWords: "",
						})
					}, 1000);
				}
			}).finally(() => {
				setState({
					status: false
				})
			})
		}
	}

	const refresh = () => {
		setState({
			showRefresh: true,
			finishText: "",
			transitionLeft: "left .3s",
			moveBlockLeft: 0,
			leftBarWidth: undefined,
			transitionWidth: "width .3s",
			leftBarBorderColor: "#ddd",
			moveBlockBackgroundColor: "#fff",
			iconColor: "#000",
			iconClass: "icon-right",
			isEnd: false,
		})

		getPictrue();
		setTimeout(() => {
			setState({
				transitionWidth: "",
				transitionLeft: "",
				text: explain,
			})
		}, 300);
	}

	useEffect(() => {
		if (state.tipWords) {
			if (state.passFlag) {
				message.success(state.tipWords)
			} else {
				message.error(state.tipWords)
			}
		}
	}, [state.tipWords])

	useMount(() => {
		setState({
			barArea: (document.querySelector(".verify-bar-area") as any)
		})
		init()
	})

	const resetSize = () => {
		var img_width, img_height, bar_width, bar_height;	//图片的宽度、高度，移动条的宽度、高度
		// const el = document.querySelector('.verifybox-bottom')
		// var parentWidth = (el && el?.parentNode as any).offsetWidth
		// var parentHeight = (el && el?.parentNode as any).offsetHeight
		var parentWidth = 330
		var parentHeight = 150

		if (imgSize.width.indexOf('%') != -1) {
			img_width = parseInt(imgSize.width) / 100 * parentWidth + 'px'
		} else {
			img_width = imgSize.width;
		}

		if (imgSize.height.indexOf('%') != -1) {
			img_height = parseInt(imgSize.height) / 100 * parentHeight + 'px'
		} else {
			img_height = imgSize.height
		}

		if (barSize.width.indexOf('%') != -1) {
			bar_width = parseInt(barSize.width) / 100 * parentWidth + 'px'
		} else {
			bar_width = barSize.width
		}

		if (barSize.height.indexOf('%') != -1) {
			bar_height = parseInt(barSize.height) / 100 * parentHeight + 'px'
		} else {
			bar_height = barSize.height
		}
		setState({
			setSize: {
				imgWidth: img_width,
				imgHeight: img_height,
				barWidth: bar_width,
				barHeight: bar_height
			}
		})
	}

	const init = () => {
		getPictrue();
		setState({
			text: explain,
		})
		resetSize()
		props.ready?.()
		const dom = (document.querySelector(".verify-bar-area") as any)
		window.removeEventListener("mousemove", function (e) {
			move(e, dom);
		});

		window.removeEventListener("mouseup", function () {
			end();
		});
	}

	// 请求背景图片和验证图片
	const getPictrue = () => {
		let data = {
			// captchaType: state.captchaType,
		};
		reqGet(data).then((response: Obj) => {
			let res = response.data.data;
			if (res.repCode == "0000") {
				setState({
					backImgBase: res.repData.originalImageBase64,
					blockBackImgBase: res.repData.jigsawImageBase64,
					backToken: res.repData.token,
					secretKey: res.repData.secretKey,
				})

			} else {
				setState({
					tipWords: res.repMsg,
				})
			}
		});
	}

	return (
		<div className='mask'>
			<div className='verifybox'>
				<div className='verifybox-top'>
					请完成安全验证
					<span className="verifybox-close" onClick={() => props.closeBox?.()}>
						<CloseOutlined className="iconfont icon-close" />
					</span>
				</div>
				<div className="verifybox-bottom" style={{ padding: 15 }}>
					<div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<div
							className="verify-img-out"
							style={{ width: state.setSize.imgWidth, height: parseInt(state.setSize.imgHeight) + vSpace }}
						>
							<div
								className="verify-img-panel"
								style={{ width: state.setSize.imgWidth, height: state.setSize.imgHeight }}
							>
								<img
									src={`data:image/png;base64,${state.backImgBase}`}
								// style="width:100%;height:100%;display:block"
								/>
								{
									state.showRefresh && (
										<div className="verify-refresh" onClick={refresh}>
											<ReloadOutlined />
										</div>
									)
								}
								{
									state.tipWords && (
										<span
											className={`"verify-tips" ${state.passFlag ? 'suc-bg' : 'err-bg'}`}
										>{state.tipWords}</span>
									)
								}
							</div>
						</div>
						<div
							className="verify-bar-area"
							style={{ width: state.setSize.imgWidth, height: barSize.height, lineHeight: barSize.height }}
						>
							<span className="verify-msg" v-text="text"></span>
							<div
								className="verify-left-bar"
								style={{ width: (state.leftBarWidth !== undefined) ? state.leftBarWidth : barSize.height, height: barSize.height, borderColor: leftBarBorderColor, transition: state.transitionWidth }}
							>
								<span className="verify-msg" v-text="finishText"></span>
								<div
									className="verify-move-block"
									onTouchMove={e => move(e, state.barArea)}
									onTouchStart={e => start(e, state.barArea)}
									onTouchEnd={end}
									onMouseDown={e => start(e, state.barArea)}
									onMouseMove={e => move(e, state.barArea)}
									onMouseUp={end}
									style={{ width: barSize.height, height: barSize.height, backgroundColor: state.moveBlockBackgroundColor, left: state.moveBlockLeft, transition: state.transitionLeft }}
								>
									<RightOutlined />
									<div
										className="verify-sub-block"
										style={{
											width: Math.floor(parseInt(state.setSize.imgWidth) * 47 / 310),
											height: state.setSize.imgHeight,
											top: -(parseInt(state.setSize.imgHeight) + vSpace),
											backgroundSize: `${state.setSize.imgWidth} ${state.setSize.imgHeight}`,
										}}
									>
										<img
											src={`data:image/png;base64,${state.blockBackImgBase}`}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Index