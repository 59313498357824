import HQChart from 'hqchart';
import tokenStore from '@/store/tokenStore';
import $ from 'jquery';
import './jscommon/umychart.resource/css/tools.css';
import './jscommon/umychart.resource/font/iconfont.css';
import { JSCHART_DATA_FIELD_ID } from './jscommon/umychart'

//简单的把K线控件封装下
function KLineChart(divKLine, _PARAMS_) {
  const { JSChart, HQChartStyle } = HQChart.Chart
  console.log(HQChart)
  const _TOKEN_ = `Bearer ${tokenStore.token}`
  this.DivKLine = divKLine;
  this.Chart = JSChart.Init(divKLine);   //把K线图绑定到一个Div上
  this.Barrage;                       //弹幕输出控制器

  //K线配置信息
  this.Option = {
    Type: '历史K线图',   //创建图形类型
    //Type:'历史K线图横屏',

    //EnableBorderDrag:false,

    Windows: //窗口指标
      [
        { Index: "EMPTY", Overlay: true },
        //{Index:"MACD"}
      ],

    DragDownload: { Day: { Enable: true }, Minute: { Enable: true } },

    EnableZoomUpDown:
    {
      //Wheel:false,
      //Keyboard:false,
      //Touch:false,
    },

    OverlayIndex:
      [

        //{Index:'BOLL', Windows:0 , IsShareY:true,ShowRightText:true },

      ],  //叠加指标


    EnableYDrag:
    {
      Right: true,
      Left: true,
    },

    Symbol: _PARAMS_.tsCode,
    IsAutoUpdate: true,       //是自动更新数据
    AutoUpdateFrequency: 5000,   //数据更新频率
    IsApiPeriod: true,

    SplashTitle: '加载数据中......',

    IsShowRightMenu: false,          //右键菜单
    //CorssCursorTouchEnd:true,
    //IsClickShowCorssCursor:true,
    //IsCorssOnlyDrawKLine:true,

    CorssCursorInfo: { DateFormatType: 3, HPenType: 1, VPenType: 1, VLineType: 0, RightButton: { Enable: true } },
    EnableZoomIndexWindow: true,

    KLine:  //K线设置
    {
      DragMode: 1,                 //拖拽模式 0 禁止拖拽 1 数据拖拽 2 区间选择
      Right: 1,                    //复权 0 不复权 1 前复权 2 后复权
      Period: 4,                   //周期 0 日线 1 周线 2 月线 3 年线
      MaxReqeustDataCount: 3000,   //数据个数
      MaxRequestMinuteDayCount: 5, //分钟数据获取几天数据  默认取5天数据
      PageSize: 5,               //一屏显示多少数据
      //Info:["互动易","大宗交易",'龙虎榜',"调研","业绩预告","公告"],       //信息地雷
      //Info:["公告"],
      IsShowTooltip: true,                 //是否显示K线提示信息
      DrawType: 3,      //K线类型 0=实心K线柱子 1=收盘价线 2=美国线 3=空心K线柱子 4=收盘价面积图
      //FirstShowDate:20161201,
      KLineDoubleClick: false, //禁止双击弹框
      RightSpaceCount: 1,
      ZoomType: 1,
      //DataWidth:5
      ShowKLine: false,
      IsShowTooltip: true,
    },

    StepPixel: 10,

    Listener:
    {
      //KeyDown:false,
      //Wheel:false
    },

    EnableFlowCapital:
    {
      BIT: true
    },

    KLineTitle: //标题设置
    {
      IsShowName: true,       //不显示股票名称
      IsShowSettingInfo: true //不显示周期/复权
    },

    Border: //边框
    {
      Left: 1,         //左边间距
      Right: 20,       //右边间距
      Bottom: 20,      //底部间距
      Top: 25,         //顶部间距

      AutoLeft: { Blank: 10, MinWidth: 30 },
      AutoRight: { Blank: 10, MinWidth: 30 },
    },

    Frame:  //子框架设置
      [
        {
          SplitCount: 6, StringFormat: 0, IsShowLeftText: false,
          PercentageTextFormat: 0,
          //IsShowIndexTitle:false,
          YCoordinateType: 0,
          //TopSpace:0,BottomSpace:0,
          //IsYReverse:true,
          IsShowYLine: true, YTextBaseline: 1,
          IsShowXLine: true,
          //SplitType:1,
          DefaultYMaxMin:
          {
            //Min:2,
            //Max:50
          },
          //BorderLine:8|2,


        },

        { SplitCount: 5 },
        { SplitCount: 4, StringFormat: 0, IsShowLeftText: false, IsShowYLine: true }
      ],

    ExtendChart:    //扩展图形
      [
        //{Name:'KLineTooltip' },  //手机端tooltip
        //{Name:"FrameSplitPaint", LineColor:"rgb(200,0,0)" }
      ],


    Overlay:
      [
        //{Symbol:'399300.sz', DrawType:0, Color:'rgb(0,0,255)'}
      ],
  };

  this.Create = function ()  //创建图形
  {
    var self = this;
    //$(window).resize(function() { self.OnSize( {Type:1} ); });    //绑定窗口大小变化事件
    $(window).resize(function () { self.OnSize(); });    //绑定窗口大小变化事件

    var blackStyle = HQChartStyle.GetStyleConfig(1); //读取黑色风格配置
    blackStyle.ToolbarButtonStyle = 1;
    //blackStyle.DRAWICON={ MinSize:20 , MaxSize:60};
    // JSChart.SetStyle(blackStyle);
    //this.DivKLine.style.backgroundColor=blackStyle.BGColor; //设置最外面的div背景

    JSChart.GetResource().ToolbarButtonStyle = 1;

    var resource = JSChart.GetResource();
    resource.OrderFlow.UpColor.Border = 'rgb(255,0,0)'
    resource.OrderFlow.UpColor.BG = 'rgb(255,255,255)'
    resource.OrderFlow.DownColor.Border = 'rgb(5,161,174)'
    resource.OrderFlow.DownColor.BG = 'rgb(255,255,255)'
    resource.OrderFlow.UnchagneColor.BG = 'rgb(255,255,255)'
    var zoom = JSChart.GetKLineZoom();
    //zoom.length=0;
    for (var i = 58; i <= 160; i += 10) {
      zoom.splice(0, 0, [i, 4]);
    }

    this.OnSize();  //让K线全屏
    this.Option.NetworkFilter = (data, callback) => { this.NetworkFilter(data, callback); }
    this.Chart.SetOption(this.Option);  //设置K线配置
    this.ChangeKLineDrawType(5)
  }

  this.NetworkFilter = function (data, callback) {
    console.log(11111, '[NetworkFilter] data', data);
    switch (data.Name) {
      case 'KLineChartContainer::RequestHistoryData':                 //日线全量数据下载
        this.RequestHistoryData(data, callback);
        break;
      case 'KLineChartContainer::RequestRealtimeData':                //日线实时数据更新
        this.RequestRealtimeData(data, callback);
        break;
      case 'KLineChartContainer::RequestFlowCapitalData':             //流通股本
        // EastMoney.HQData.NetworkFilter(data, callback);
        break;
      case 'KLineChartContainer::ReqeustHistoryMinuteData':           //分钟全量数据下载
        this.ReqeustHistoryMinuteData(data, callback);
        break;
      case 'KLineChartContainer::RequestMinuteRealtimeData':          //分钟增量数据更新
        this.RequestMinuteRealtimeData(data, callback);
        break;
    }
  }

  this.CreateTestOrderFlowData = function (hqchartData)  //生成订单流测试数据
  {
    var itemAskBG = ['rgb(0,255,255)', 'rgb(255,0,255)', "rgb(255,182,193)"];
    var itemBidBG = ['rgb(173,255,47)', 'rgb(255,127,80)', "rgb(32,178,170)"];

    for (var i = 0; i < hqchartData.data.length; ++i) {
      var kItem = hqchartData.data[i];
      var high = kItem[3];
      var low = kItem[4];
      //if (high==low) continue;

      var aryOrder = [];
      var testBarWidth = this.GetRandomTestData(0, 40) + this.GetRandomTestData(0, 10) / 10.0;
      var testBar2Width = this.GetRandomTestData(0, 30) + this.GetRandomTestData(0, 10) / 10.0;
      for (var price = low, j = 0; price <= high; ++price, ++j) {
        var order =
        {
          Price: price,
          Ask: { Value: this.GetRandomTestData(0, 500) },
          Bid: { Value: this.GetRandomTestData(0, 500) }
        }

        if (order.Ask.Value > 450) order.Ask.Color = 'rgb(108,97,131)';
        if (order.Bid.Value > 400) order.Bid.Color = "rgb(102,135,156)";

        if (order.Ask.Value > 460 && order.Bid.Value > 460) {
          order.Ask.BG = itemAskBG[price % itemAskBG.length];
          order.Ask.Text = "1,112";
          order.Ask.Font = { Weight: "bold" };
          order.Bid.BG = itemAskBG[price % itemAskBG.length];
        }

        if (i > 0 && i % 33 == 0 && j == 0) {
          order.Ask.BG = order.Bid.BG = itemAskBG[price % itemAskBG.length];
          order.HBar = { Color: "rgb(240,128,128)", Width: testBarWidth, Height: -3, Type: 1 };
        }
        else if (i > 0 && i % 44 == 0 && j == 4) {
          order.Ask.BG = order.Bid.BG = itemBidBG[price % itemAskBG.length];
          order.HBar = { Color: "rgb(127,255,170)", Width: testBar2Width, Type: 2 };
        }

        if (i % 10 && j == 5) order.Ask.Font = { Weight: "bold" };
        if (i % 10 && j == 4) order.Bid.Font = { Weight: "bold" };

        aryOrder.push(order);
      }

      var orderFlowItem =
      {
        Order: aryOrder, PriceOffset: 1, //PriceOffset 每个订单价格差
        High:
          [
            { Price: high + 1, Text: this.GetRandomTestData(600, 5000).toString() },
            { Price: high + 4, Text: this.GetRandomTestData(600, 5000).toString(), Color: itemAskBG[price % itemAskBG.length] }
          ],
        Low:
          [
            { Price: low - 1, Text: this.GetRandomTestData(600, 5000).toString() },
            { Price: low - 2, Text: this.GetRandomTestData(600, 5000).toString(), Color: itemBidBG[price % itemAskBG.length] }
          ]
      };

      if (i % 10 == 0) orderFlowItem.High[1].Font = { Weight: "bold" };
      if (i % 10 == 0) orderFlowItem.Low[0].Font = { Weight: "bold" };

      kItem[JSCHART_DATA_FIELD_ID.KLINE_ORDERFLOW] = orderFlowItem;
    }
    console.error(JSCHART_DATA_FIELD_ID, hqchartData)
  }

  this.RequestHistoryData = function (data, callback)    //全量日K线
  {
    data.PreventDefault = true;
    var requestData = data.Request.Data;
    var symbol = requestData.symbol; //请求的股票代码
    var period = requestData.period;    //周期
    var right = requestData.right;      //复权
    var kLinedrawType = requestData.klineDrawType;    //K线类型

    console.log(`[KLineChart::RequestHistoryData] 代码:${symbol}, 周期:${period}, K线类型:${kLinedrawType}`);

    // setTimeout(() => {
    // 	var hqchartData = CM_114_K_Day_Data;
    // 	if (kLinedrawType == 5) this.CreateTestOrderFlowData(hqchartData);

    // 	console.log('[KLineChart::RequestHistoryData] hqchartData=', hqchartData);
    // 	callback(hqchartData);
    // }, 50);

  }

  var kdata = [];

  this.ReqeustHistoryMinuteData = async function (data, callback)  //全量分钟K线
  {
    data.PreventDefault = true;
    var requestData = data.Request.Data;
    var symbol = requestData.symbol; //请求的股票代码
    var period = requestData.period;    //周期
    var right = requestData.right;      //复权
    var kLinedrawType = requestData.klineDrawType;    //K线类型

    console.error(222222, symbol, period, requestData, `代码:${symbol}, 周期:${period}, K线类型:${kLinedrawType}`);

    await $.ajax({
      url: 'https://hkcap.com.cn/stock/orderFlow/hq/k',
      data: _PARAMS_,
      type: "post",
      dataType: "json",
      headers: {
        "Authorization": _TOKEN_
      },
      async: true,
      success: (recv1) => {
        this.RecvHistoryMinuteData(recv1.data, data, callback);
        $.ajax({
          url: 'https://hkcap.com.cn/stock/orderFlow/hq/order-flow',
          data: _PARAMS_,
          type: "post",
          dataType: "json",
          headers: {
            "Authorization": _TOKEN_
          },
          async: true,
          success: (recs2) => {
            console.error(444444, recs2, kLinedrawType)
            if (kLinedrawType == 5) {
              var list = []
              for (var i = 0; i < recv1.data.data.length; ++i) {
                var kItem = recv1.data.data[i];
                kItem[JSCHART_DATA_FIELD_ID.KLINE_ORDERFLOW] = recs2.data[i];
                list.push(kItem)
              }
            }
            recv1.data = {
              ...recv1.data,
              code: 0,
              data: list
            }
            console.error(66666, recv1.data)
            callback(recv1.data);
          },
        });
      },
      error: (request) => {
        //self.RecvError(request,RECV_DATA_TYPE.DERIVATIVE_DATA);
      }
    });

    // setTimeout(() => {
    //   var hqchartData = CM_114_K_15Minute_Data;
    //   if (kLinedrawType == 5) this.CreateTestOrderFlowData(hqchartData);
    //   console.error(77777, hqchartData)
    //   callback(hqchartData);
    // }, 50);

  }

  this.RecvHistoryMinuteData = function (recvData, data, callback)   //接收历史分钟数据
  {
    //把数据转换成hqchart格式， 格式具体看教程
    var hqChartData = { code: 0, data: recvData.data };
    hqChartData.symbol = recvData.symbol;
    hqChartData.name = recvData.name;
    console.error(55555, hqChartData);
    callback(hqChartData); //数据设置到hqchart里面
  }

  this.RequestMinuteRealtimeData = function (data, callback) //增量分钟K
  {
    data.PreventDefault = true;
    var requestData = data.Request.Data;
    var symbol = requestData.symbol[0];     //请求的股票代码
    var period = requestData.period;      //周期
    var right = requestData.right;        //复权
    var kLinedrawType = requestData.klineDrawType;    //K线类型

    // console.log(`[KLineChart::RequestMinuteRealtimeData] 代码:${symbol}, 周期:${period}, K线类型:${kLinedrawType}`);
    // var hqchartData = CM_114_K_15Minute_Update_Data;
    // if (kLinedrawType == 5) this.CreateTestOrderFlowData(hqchartData);
    // callback(hqchartData);
  }

  this.RequestRealtimeData = function (data, callback) {
    data.PreventDefault = true;
    var requestData = data.Request.Data;
    var symbol = requestData.symbol[0];   //请求的股票代码
    var period = requestData.period;      //周期
    var right = requestData.right;        //复权
    var kLinedrawType = requestData.klineDrawType;    //K线类型

    console.log(`[KLineChart::RequestRealtimeData] 代码:${symbol}, 周期:${period}, K线类型:${kLinedrawType}`);

    var hqchartData = CM_114_K_Day_Update_Data;
    if (kLinedrawType == 5) {
      var stock = hqchartData.stock[0];
      var low = stock.low, high = stock.high;
      var itemAskBG = ['rgb(0,255,255)', 'rgb(255,0,255)', "rgb(255,182,193)"];
      var itemBidBG = ['rgb(173,255,47)', 'rgb(255,127,80)', "rgb(32,178,170)"];

      var aryOrder = [];
      for (var price = low, i = 0; price <= high; ++price, ++i) {
        var order =
        {
          Price: price,
          Ask: { Value: this.GetRandomTestData(0, 500) },
          Bid: { Value: this.GetRandomTestData(0, 500) }
        }

        if (order.Ask.Value > 450) order.Ask.Color = 'rgb(108,97,131)';
        if (order.Bid.Value > 400) order.Bid.Color = "rgb(102,135,156)";

        if (order.Ask.Value > 460 && order.Bid.Value > 460) {
          order.Ask.BG = itemAskBG[price % itemAskBG.length];
          order.Ask.Text = "4,112";
          order.Bid.BG = itemAskBG[price % itemAskBG.length];
        }

        aryOrder.push(order);
      }

      stock.OrderFlow = {
        Order: aryOrder, PriceOffset: 1, //PriceOffset 每个订单价格差
        High:
          [
            { Price: high + 1, Text: this.GetRandomTestData(600, 5000).toString() },
            { Price: high + 4, Text: this.GetRandomTestData(600, 5000).toString(), Color: itemAskBG[price % itemAskBG.length] }
          ],
        Low:
          [
            { Price: low - 1, Text: this.GetRandomTestData(600, 5000).toString() },
            { Price: low - 2, Text: this.GetRandomTestData(600, 5000).toString(), Color: itemBidBG[price % itemAskBG.length] }
          ]
      };
    };

    console.log('[KLineChart::RequestRealtimeData] hqchartData=', hqchartData);
    callback(hqchartData);
  }


  this.GetRandomTestData = function (min, max)   //测试数据
  {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值
  }

  this.OnSize = function (option)  //自适应大小调整
  {
    var height = $(divKLine).height();
    var width = $(divKLine).width();
    //width=50000;
    this.DivKLine.style.top = '0px';
    this.DivKLine.style.left = '0px';
    this.DivKLine.style.width = width + 'px';
    this.DivKLine.style.height = height + 'px';
    this.Chart.OnSize(option);
  }

  this.ChangeSymbol = function (symbol) {

    this.Chart.ChangeSymbol(symbol, option);
  }

  this.ChangeKLineDrawType = function (type) {
    this.Chart.ChangeKLineDrawType(type);
  }
}

export default KLineChart
