import { Button, Form } from "antd";
import FormField, { FieldType } from "../formField";
import './index.less';

interface SearchFieldProps {
	label: string;
	name: string;
	required?: boolean;
	type: FieldType;
	rangePicker?: boolean;
}
interface FormItemProps {
	searchFields: any[];
	search: (values: Record<string, any>) => void;
	reset?: () => void;
	formRef?: any;
	noReset?: boolean;
	btnDisabled?: boolean;
	initialValues?: Record<string, any>;
}
export default function PageSearch(props: FormItemProps) {
	const [form] = Form.useForm();
	const search = async () => {
		await form.validateFields()
		props.search(form.getFieldsValue())
	}
	const reset = () => {
		form.resetFields();
		props.reset?.();
	}
	return <div className="toolbar">
		<Form form={form} layout="inline" ref={props?.formRef} initialValues={props.initialValues}>
			{
				props.searchFields.map((i, index) => {
					return <Form.Item label={i.label} name={i.name} key={index} rules={[{ required: i.required }]}>
						<FormField {...i} />
					</Form.Item>
				})
			}
		</Form>
		<Button type="primary" onClick={search} disabled={props.btnDisabled || false}>查询</Button>
		{
			!props.noReset && <Button onClick={reset} style={{ marginLeft: 8 }} disabled={props.btnDisabled || false}>重置</Button>
		}
	</div>
}
