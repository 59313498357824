import './index.less';
import { debounce, isArray } from 'lodash';
import { DatePicker, Input, InputNumber, Select, Spin } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

export type FieldType = '单文本' | '多文本' | '单选' | '多选' | '整数' | '浮点' | '日期' | '附件';

interface FieldValueMap {
  单文本: string;
  多文本: string;
  单选: string;
  多选: string[];
  整数: number;
  浮点: number;
  日期: number;
  附件: string[];
}
type FieldValue<T extends FieldType> = FieldValueMap[T];

interface FieldSettingProps {
  selectCom?: boolean;
  loading?: boolean;
  handleSearch?: (v: string) => void;
}

export interface FormFieldProps<T extends FieldType> extends FieldConfigBase {
  // name: string;
  value?: any;
  formInfo?: any;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: any) => void;
  showTime?: boolean;
  dateFormat?: string;
  settings?: FieldSettingProps;
  rangePicker?: boolean;
}

export default function FormField<T extends FieldType>(props: FormFieldProps<T>) {
  const {
    // name,
    type,
    value,
    onChange = () => { },
    disabled,
    placeholder,
    options,
    min,
    max,
    decimal,
    picker,
    maxCount,
    maxSize,
    showTime,
    dateFormat,
    settings,
    rangePicker,
  } = props;

  const search = (v: string) => {
    // let time: string | number | NodeJS.Timeout | null | undefined = null
    // if (time !== null) {
    //   clearTimeout(time);
    // }
    // time = setTimeout(() => {
    settings?.handleSearch?.(v)
    // }, 1000)
  }

  const selectOptions = (options || []).map(o => ({
    label: o,
    value: o,
  }));

  let control;
  let parsed;

  const commonProps = {
    style: {
      width: '100%',
      minWidth: '120px',
    },
    disabled,
  };

  switch (type) {
    case '单文本':
      control = !settings?.selectCom ? (
        <Input
          {...commonProps}
          placeholder={!disabled ? placeholder || '请输入' : ''}
          value={value as string}
          onChange={e => {
            onChange(e.target.value);
          }}
        />
      ) : (
        <Select
          showSearch
          value={value}
          placeholder={props.placeholder}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={debounce(search, 400)}
          onChange={v => {
            onChange(v);
          }}
          loading={settings.loading || false}
          style={{ width: 180 }}
          options={options as any || []}
          notFoundContent={settings.loading ? <Spin size="small" /> : '无结果'}
        />
      );
      break;
    case '多文本':
      control = (
        <Input.TextArea
          {...commonProps}
          placeholder={placeholder || '请输入'}
          value={value as string}
          onChange={e => {
            onChange((e.target as Element & { value: string }).value);
          }}
        />
      );
      break;
    case '单选':
      control = (
        <Select
          {...commonProps}
          placeholder={placeholder || '请选择'}
          value={isArray(value) ? value[0] : value}
          options={selectOptions}
          onChange={(v, option) => {
            onChange(v);
          }}
        />
      );
      break;
    case '多选':
      control = (
        <Select
          {...commonProps}
          placeholder={placeholder || '请选择'}
          mode="multiple"
          value={value}
          options={selectOptions}
          onChange={(v, option) => {
            onChange(v);
          }}
        />
      );
      break;
    case '整数':
    case '浮点':
      control = (
        <InputNumber
          {...commonProps}
          placeholder={placeholder || '请输入'}
          min={min}
          max={max}
          precision={decimal}
          value={value as number}
          onChange={v => {
            onChange(v);
          }}
        />
      );
      break;
    case '日期':
      // parsed = parseInt(value as any);
      // if (parsed > 0) {
      //   parsed = moment(parsed * 1000);
      // } else {
      //   parsed = undefined;
      // }
      if (!rangePicker) {
        parsed = value ? moment(value) : null;
        control = (
          <DatePicker
            {...commonProps}
            showTime={showTime}
            placeholder={placeholder || '请选择日期'}
            picker={picker === 'time' ? 'date' : picker}
            disabledDate={date => {
              if (min !== undefined && min > 0) {
                return date.unix() < min;
              }
              if (max !== undefined && max > 0) {
                return date.unix() > max;
              }
              return false;
            }}
            value={parsed}
            onChange={v => {
              // onChange(v?.unix());
              onChange(v);
            }}
            locale={locale}
          />
        );
      } else {
        parsed = value ? [value[0] ? moment(value[0]) : null, value[1] ? moment(value[1]) : null] : null;
        control = (
          <DatePicker.RangePicker
            // {...commonProps}
            // showTime={showTime}
            // placeholder={placeholder || '请选择日期'}
            // picker={picker === 'time' ? 'date' : picker}
            // disabledDate={date => {
            //   if (min !== undefined && min > 0) {
            //     return date.unix() < min;
            //   }
            //   if (max !== undefined && max > 0) {
            //     return date.unix() > max;
            //   }
            //   return false;
            // }}
            // @ts-ignore
            value={parsed}
            onChange={v => {
              // onChange(v?.unix());
              onChange(v);
            }}
            locale={locale}
          />
        );
      }
      break;
    default:
      control = <></>;
      break;
  }
  return control;
}

export interface FieldConfigBase {
  type: FieldType;
  uuid?: string;
  options?: string[];
  min?: number;
  max?: number;
  decimal?: number;
  picker?: 'time' | 'date' | 'week' | 'month' | 'year';
  maxCount?: number;
  maxSize?: number;
  /**初始值 */
  value?: any;
  disabled?: boolean;
  placeholder?: string;
  associateTable?: any; // 关联表
  form?: any;
  formInfo?: any;
  rowIndex?: number; // 当前编辑数据所在行（子表）
}

export interface FieldConfig extends FieldConfigBase {
  title: string;
  key: string;
  required?: boolean;
  hint?: string;
  associateTable?: Record<string, any> | null;
}
